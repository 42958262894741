import { useState,useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

import Notification from "../partials/notification"
const SITE_KEY = process.env.REACT_APP_API_KEY
const URLTOSENDMAIL=process.env.REACT_APP_API_SEND
const sms_Key= process.env.REACT_APP_API_SMS


;
function Newsletter(props) {
  function onChange(value) {
    console.log("Captcha value:", value);
  }
   
  const [FirstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [isHuman,setIsHuman ] = useState(false)
 
  const recaptchaRef = useRef();
 
  const [showElement,setShowElement] = useState(false)



  function onChange(value) {
    console.log("Captcha value:", value);
    if(value!=null)
    {

     setIsHuman(true)
    }
  }


  
const sendEmail = (e)=>{
  setShowElement(true)
  e.preventDefault()

  var axios = require('axios');
  var qs = require('qs');
  var data = qs.stringify({
    'Number': '97455955122',
    'Body': 'client name: '+FirstName+' '+'LastName: '+LastName+' '+'phone number: '+PhoneNumber 
  });
  var config = {
    method: 'post',
    url: URLTOSENDMAIL,
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded', 
      'AppSid': sms_Key
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {

  })
  .catch(function (error) {
;
  });
  





 
  setTimeout(function() {
    setFirstName("")
    setLastName("")
    setPhoneNumber("")
    setIsHuman(false)
    setShowElement(false)
       }, 2000);

}


  return (
    <>
    
 

    <section className='bg-white '>
   



      <div className="max-w-6xl mx-auto px-4 sm:px-6 bg-white">
      <div>
  
  {showElement?<div><Notification/></div>:<></>} 
</div>
        <div className="pb-12 md:pb-20">

          {/* CTA box */}
          <div className="relative bg-gray-900 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl overflow-hidden" data-aos="zoom-y-out">

            {/* Background illustration */}
            <div className="absolute right-0 bottom-0 pointer-events-none hidden lg:block" aria-hidden="true">
              <svg width="428" height="328" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <radialGradient cx="35.542%" cy="34.553%" fx="35.542%" fy="34.553%" r="96.031%" id="ni-a">
                    <stop stopColor="#DFDFDF" offset="0%" />
                    <stop stopColor="#4C4C4C" offset="44.317%" />
                    <stop stopColor="#333" offset="100%" />
                  </radialGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <g fill="#FFF">
                    <ellipse fillOpacity=".04" cx="185" cy="15.576" rx="16" ry="15.576" />
                    <ellipse fillOpacity=".24" cx="100" cy="68.402" rx="24" ry="23.364" />
                    <ellipse fillOpacity=".12" cx="29" cy="251.231" rx="29" ry="28.231" />
                    <ellipse fillOpacity=".64" cx="29" cy="251.231" rx="8" ry="7.788" />
                    <ellipse fillOpacity=".12" cx="342" cy="31.303" rx="8" ry="7.788" />
                    <ellipse fillOpacity=".48" cx="62" cy="126.811" rx="2" ry="1.947" />
                    <ellipse fillOpacity=".12" cx="78" cy="7.072" rx="2" ry="1.947" />
                    <ellipse fillOpacity=".64" cx="185" cy="15.576" rx="6" ry="5.841" />
                  </g>
                  <circle fill="url(#ni-a)" cx="276" cy="237" r="200" />
                </g>
              </svg>
            </div>

            <div className="relative flex flex-col lg:flex-row justify-between items-center">

              {/* CTA content */}
              <div className="text-center lg:text-left lg:max-w-xl">
                <h3 className="h3 text-white mb-2">Let’s build something great together!</h3>
                <p className="text-gray-300 text-lg mb-6">You are one step away from  your digital growth</p>

                {/* CTA form */}
                <div className="w-full lg:w-auto">
                  <div className="flex flex-col sm:flex-col justify-center max-w-xs mx-auto sm:max-w-md lg:mx-0">
                    <input  onChange={e => setFirstName(e.target.value)} value={FirstName} type="text"className="w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500" placeholder="First Name" aria-label="First Name"  style={{marginTop:15}} />
                    <input type="text" onChange={e => setLastName(e.target.value)} value={LastName}  className="w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500" placeholder="Last Name" aria-label="Last Name" style={{marginTop:15}}/>
                    <input type="text"  onChange={e => setPhoneNumber(e.target.value)} value={PhoneNumber}className="w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500" placeholder="Your phone Number With country code ex:974" aria-label="Your Phone Number"  style={{marginTop:15}}/>



                  
                    <button  onClick={sendEmail} disabled={!isHuman} className={`${isHuman&&FirstName&&LastName&&PhoneNumber?"btn text-white bg-blue-600 shadow items-center   mt-2  cursor-pointer":" btn bg-gray-300 from-gray-300 to-gray-300 bord text-black cursor-not-allowed items-center"}` } style={{marginTop:15,height:50,display:"flex",justifyContent:"center",alignContent:"center",textAlign:"center"}} >

                    {loading ? 'Submitting...' : 'Lets Bulid'}
     </button>
                  </div>

         

                  <div className='mt-8'>
        




      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={SITE_KEY}
        onChange={onChange}
      />



  </div>

    
  
                  

              </div>
      
              </div>

            </div>

          </div>
    
        </div>
      </div>
  
    </section>
   
</>
  );
}

export default Newsletter;
