import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <nav>
      <div className='container'>
        <div className='nav-columns'>
          <div className='nav-column'>
            <div className='nav-label'>Menu</div>
            <ul className='nav-links'>
              <li>
                <NavLink to='/Our-Work' exact>
                Our Work
                </NavLink>
              </li>
              <li>
                <NavLink to='/About-Us' exact>
                About Us
                </NavLink>
              </li>
             
           
            </ul>
          </div>
          <div className='nav-column'>
            <div className='nav-label'>Contact</div>
            <div className='nav-infos'>
              <ul className='nav-info'>
                <li className='nav-info-label'>Email</li>
                <li>
                  <NavLink to='/' exact>
                    Get in touch with us
                  </NavLink>
                </li>
                <li className='nav-info-label'>Phone</li>
                <li>+97455955122</li>
              </ul>
              <ul className='nav-info'>
                <li className='nav-info-label'>Headquarter</li>
                <li>Rawdat Al Khail</li>
                <li>Doha</li>
                <li>Qatar</li>
                <li className='nav-info-label'>Legal</li>
                <li>Privacy & Cookies</li>
              </ul>
            
              <ul className='nav-info'>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
