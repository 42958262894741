import React from "react";

const Banner = () => {
  return (
    <section className='main'>
      <div className='container'>
        <div className='row'>
          <h2>
            <div className='line'>
              <span>We Help You Break </span>
            </div>
            <div className='line'>
              <span> Into Digital World</span>
            </div>
          </h2>
          <div className='btn-row'>
            <a href='/'>
              {/* More about us <RightArrow /> */}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
