
/* This example requires Tailwind CSS v2.0+ */
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline'

export default function Notification() {
  return (
    <div class="sticky top-0 z-50 animate-fade" style={{backgroundColor:"#fff2d8"}}>
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8 h-100">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-greeen-800">
            </span>
            <p className="ml-3 font-medium text-black truncate">
              <span className="hidden md:inline">Thank you for trusted us, Our team will conact you </span>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
           
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <span className="sr-only">Dismiss</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}


