import React from "react";
import { AnnotationIcon, ViewGridAddIcon, LightningBoltIcon, ChipIcon,DocumentSearchIcon } from '@heroicons/react/outline'
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';
import logo_1 from "../images/logo192.png"
import logo_2 from "../images/react.png"
import logo_3 from "../images/dot.png"
import logo_4 from "../images/spring.png"
import logo_5 from "../images/mogo.png"
import logo_6 from "../images/MySQL-logo.png"
import logo_7 from "../images/vmware-logo.png"
import img_1 from "../images/img_1.png"
import img_2 from "../images/img_2.jpeg"
const callouts = [
  {
    name: 'FGREALTY For Clients',
    description: 'is tools to buy,  or rent a home and keep millions of listings right at your fingertips',
    imageSrc: 'https://www.fgrealty.qa/images/fgrealty-logo.webp',
    imageSrcBK:'https://i.ibb.co/2ZZXfYg/img-1.png',
    imageAlt: 'FGREALTY ',
    href: 'https://www.fgrealty.qa',
  },
  {
    name: 'FGREALTY For Agents',
    description: 'We make the real estate process even more simple by creating agent application',
    imageSrc: 'https://www.fgrealty.qa/images/fgrealty-logo.webp',
    imageSrcBK:"https://i.ibb.co/g4m1QvC/img-2.jpg",
    imageAlt: 'FGREALTY ',
    href: 'https://www.fgrealty.qa',
  },
  {
    name: 'Povti Cloud communcation platfrom',
    description: 'Povti provide many communication services such as two factor authentication (OTP),Bulk SMS ,SMS API',
    imageSrc: 'https://www.linkpicture.com/q/Screen-Shot-2022-01-28-at-1.45.20-AM.png',
    imageSrcBK:"https://i.ibb.co/wJJGtth/226995-2121x1414-Texting-On-Mobile-Phone.jpg",
    imageAlt: 'Pov I provide many communication services such as two factor authentication (OTP),Bulk SMS ,SMS API',
    href: 'https://www.povti.com',
  },
]



const calloutsTwo = [
  {
    name: 'H&B For Clients',
    description: 'H&B is mobile app that provide health and beauty service and product ',
    imageSrc: 'https://www.linkpicture.com/q/Monogram-HB-Logo-V2-Graphics-5153172-1.jpeg',
    imageSrcBK:"https://i.ibb.co/Gd1vGFn/dermstoremock-1586988986.png",
    imageAlt: 'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
    href: '#',
  },
  {
    name: 'H&B For service provider and store owner',
    description: 'We create ecommerce more easy with providing the service provider and store app to mange and accept orders easy',
    imageSrc: 'https://i.ibb.co/SBphv17/back.jpg',
    imageSrcBK:"https://i.ibb.co/CMcwqQH/ecommerce-app-development-company-dubai.jpg",
    imageAlt: 'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
    href: '#',
  },
  {
    name: 'H&B For Drivers',
    description: 'we change concept of delivery by creating H&B driver App that make the delivery of item more fast',
    imageSrc: 'https://www.linkpicture.com/q/Monogram-HB-Logo-V2-Graphics-5153172-1.jpeg',
    imageSrcBK:"https://i.ibb.co/HGLhSMJ/istockphoto-1146631483-612x612.jpg",
    imageAlt: 'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
    href: '#',
  },
]
const calloutsThree= [
  {
    name: 'QAVA',
    description: 'online coffee marketplace ,QAVA App caters to all your coffee needs ',
    imageSrc: 'https://www.linkpicture.com/q/letter-q-for-coffee-beans-logo-icon-design-vector-38166534.jpeg',
    imageSrcBK:"https://i.ibb.co/6Rr6zHK/2-Costa-Club-app-gift-a-reward-2021-1.jpg",
    imageAlt: 'FGREALTY ',
    href: '#',
  },
  {
    name: 'QAVA For Coffee Shops',
    description: 'We create  more easy with providing the service provider and store app to mange and accept orders easy ',
    imageSrc: 'https://www.linkpicture.com/q/letter-q-for-coffee-beans-logo-icon-design-vector-38166534.jpeg',
    imageSrcBK:"https://i.ibb.co/zVvGM2C/istockphoto-638695448-612x612.jpg",
    imageAlt: 'FGREALTY ',
    href: '#',
  },
  {
    name: 'QAVA for Driver',
    description: 'we change concept of delivery by creating QAVA driver App that make the delivery of Coffee more fast',
    imageSrc: 'https://www.linkpicture.com/q/letter-q-for-coffee-beans-logo-icon-design-vector-38166534.jpeg',
    imageSrcBK:"https://i.ibb.co/5KbYSdx/2017-10-27-1.jpg",
    imageAlt: 'FGREALTY ',
    href: '#',
  },
]

const calloutsFour = [
  {
    name: 'ESPORTFIT',
    description: 'EsportFit is mobile application provide sporting Goods and Shop a Wide Selection of Sports Gear, Equipment, Apparel and Footwear',
    imageSrc: 'https://www.linkpicture.com/q/Monogram-EF-Logo-by-Greenlines-Studios.jpeg',
    imageSrcBK:"https://i.ibb.co/jf9JGJs/istockphoto-958491892-640x640.jpg",
    imageAlt: 'FGREALTY ',
    href: 'https://www.fgrealty.qa',
  },
  {
    name: 'Utlubly',
    description: 'Utlubly application allows you to shop from global commercial sites and pay on delivery!',
    imageSrc: 'https://www.linkpicture.com/q/Screen-Shot-2022-01-28-at-2.14.14-AM.png',
    imageSrcBK:"https://i.ibb.co/KF3RRDY/images.jpg",
    imageAlt: 'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
    href: '#',
  },

]

const features = [
  {
    name: 'Competitive Analysis',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: DocumentSearchIcon,
  },
  {
    name: 'Establish key features for MVP',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: LightningBoltIcon,
  },
  {
    name: 'Technology Research & Validation',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: ChipIcon,
  },
  {
    name: 'Start Bulding full Product',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: ViewGridAddIcon,
  },
]
const CaseStudies = () => {
  return (
    <div className='page'>
       <div className="relative bg-white overflow-hidden" >
      <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48" style={{marginTop:100}}>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
          <div className="sm:max-w-lg">
            <h1 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-6xl">
            We are experts at
            </h1>
            <p className="mt-4 text-xl text-gray-500">
           innovative solutions that will fit our customer's requirements and aim for a long-lasting partnership. Our professional team is ready to provide you with the high-quality service  bring your innovative ideas to life. Engineeric is using the most modern and most demanded technologies to create an outstanding project
            </p>
          </div>
          <div>
            <div className="mt-30">
              {/* Decorative image grid */}
              <div
                aria-hidden="true"
                className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
              >
                <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                  <div className="flex items-center space-x-6 lg:space-x-8">
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="w-40 h-44 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100 cover">
                        <img
                          src={logo_2}
                          alt=""
                          className="w-44 h-30"  style={{height:"80%"}}
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                      <img
                          src={logo_7}
                          alt=""
                          className="w-full object-center object-cover" style={{height:"50%"}}
                        />
                      </div>
                    </div>
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8 mt-10">
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src={logo_6}
                          alt=""
                          className="" style={{height:"70%"}}
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src={logo_1}
                          alt=""
                          className="w-full object-center object-cover" style={{height:"64%"}}
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src={logo_3}
                          alt=""
                          className="" style={{height:"50%"}}
                        />
                      </div>
                    </div>
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                             src={logo_4}
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src={logo_5}
                          alt=""
                          className="" style={{height:"90%"}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
          <h2 className="text-2xl font-extrabold text-gray-900">Our  Work</h2>

          <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
          {callouts.map((callout,index) => (

              <div key={callout.name} className="group relative" >
                <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">

              
                  
                  <img
                   
                    src={callout.imageSrcBK}
                    alt={callout.imageAlt}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <h3 className="mt-6 text-sm text-gray-500">
                  <a href={callout.href}>
                    <span className="absolute inset-0" />
                    {callout.name}
                  </a>
                </h3>
                <p className="text-base font-semibold text-gray-900">{callout.description}</p>
              </div>
            ))}
               {calloutsTwo.map((callout) => (
              <div key={callout.name} className="group relative" style={{marginTop:100}}>
                <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">

                
                  
                  <img
                    src={callout.imageSrcBK}

                    alt={callout.imageAlt}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <h3 className="mt-6 text-sm text-gray-500">
                  <a href={callout.href}>
                    <span className="absolute inset-0" />
                    {callout.name}
                  </a>
                </h3>
                <p className="text-base font-semibold text-gray-900">{callout.description}</p>
              </div>
            ))}





{calloutsThree.map((callout) => (
              <div key={callout.name} className="group relative" style={{marginTop:100}}>
                <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">

              
                  
                  <img
                    src={callout.imageSrcBK}

                    alt={callout.imageAlt}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <h3 className="mt-6 text-sm text-gray-500">
                  <a href={callout.href}>
                    <span className="absolute inset-0" />
                    {callout.name}
                  </a>
                </h3>
                <p className="text-base font-semibold text-gray-900">{callout.description}</p>
              </div>
            ))}



{calloutsFour.map((callout) => (
              <div key={callout.name} className="group relative" style={{marginTop:100}}>
                <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">

                 
                  
                  <img
                    src={callout.imageSrcBK}

                    alt={callout.imageAlt}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <h3 className="mt-6 text-sm text-gray-500">
                  <a href={callout.href}>
                    <span className="absolute inset-0" />
                    {callout.name}
                  </a>
                </h3>
                <p className="text-base font-semibold text-gray-900">{callout.description}</p>
              </div>
            ))}



          </div>
        </div>
      </div>
    </div>


    
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Strategy & Product Definition
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          We can help you transform an idea into a fully viable product, starting by defining the goals and building a roadmap to the best possible solution
          </p>
        </div>

        <div className="mt-20 mb-20">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-black text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  {/* <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p> */}
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.name}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
<Newsletter/>
<Footer/>

    </div>
  );
};

export default CaseStudies;
