import React from "react";
import webdev from "../images/team-web.jpg"
import mobildev from "../images/mobile-dev.jpg"
import network from  "../images/network-team.jpg" 
const caseStudies = [
  {
    id: 1,
    subtitle: "Mobile Development",
    title: "We Bulid Your Idea From Scratch To Market",
    img: "curology-min",
    url:webdev
  },
  {
    id: 2,
    subtitle: "Web App Development",
    title: "We Build Web Apps Are Scalable & Smart",
    img: "yourspace-min",
    url:mobildev
  },
  {
    id: 3,
    subtitle: "Networking & Virtualization ",
    title: "We Make Sure Your App Running Perfect",
    img: "lumin-min",
    url:network
  }
];

const Cases = () => {
  return (
    <section className='cases' >
      <div className='container-fluid'>
        <div className='cases-navigation'>
          {/* <div className='cases-arrow prev disabled'>
            <CasesPrev />
          </div> */}
          {/* <div className='cases-arrow next'>
            <CasesNext />
          </div> */}
        </div>
        <div className='row'>
          {caseStudies.map(caseItem => (
            <div className='case' key={caseItem.id}>
              <div className='case-details'>
                <span>{caseItem.subtitle}</span>
                <h2>{caseItem.title}</h2>
              </div>
              <div className='case-image'>
                <img
                  src={caseItem.url}
                  alt={caseItem.title}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Cases;
