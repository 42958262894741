
import { SparklesIcon, CodeIcon, CurrencyDollarIcon, TrendingUpIcon,ShieldCheckIcon,EmojiHappyIcon,ChipIcon,ClipboardCheckIcon,HeartIcon,ArrowsExpandIcon } from '@heroicons/react/outline'
import React from 'react';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';
import img_1 from "../images/our.jpeg";
import { Switch, Route, useLocation } from 'react-router-dom';
const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function Approach() {
  
  const location = useLocation();
  // Scroll to top if path changes
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <div className="relative bg-white overflow-hidden ">
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

         

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-100 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left sm:mt-100 ">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl sm:mt-100" style={{marginTop:100}}>
                <span className="block xl:inline sm:mt-100 ">we proud that we are local Qatari Technology company </span>{' '}
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Our Treasure Comprises Our People, Vision & Values              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://api.time.com/wp-content/uploads/2017/07/qatar-settles-in-for-long-standoff.jpg?w=1600&quality=70"
          alt=""
        />
      </div>
      </div>
{/* here main */}

  <section className="text-gray-700 body-font border-t border-gray-200">
    <div className="container px-5 py-24 mx-auto flex flex-wrap">
    <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
        Our Mission
        </h1>
        <p className="lg:w-1/2 w-full leading-relaxed text-base">
        To empower businesses digitally and help them gain a competitive edge by providing simple yet innovative technology solutions.
        </p>
      </div>
      <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
    
        
        <img
          alt="feature"
          className="object-cover object-center h-full w-full"
          src={img_1}
        />
      </div>
      <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
        <div className="flex flex-col mb-10 lg:items-start items-center">
          <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-black text-indigo-500 mb-5">
          <CodeIcon className="h-6 w-6  text-white" aria-hidden="true"/>
              <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
          
          </div>
          <div className="flex-grow">
            <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
            Digital transformation 
            </h2>
            <p className="leading-relaxed text-base">
            Realize the potential of digital transformation and make their businesses compatible with the digitally-connected world
            </p>
         
          </div>
        </div>
        <div className="flex flex-col mb-10 lg:items-start items-center">
          <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-black text-indigo-500 mb-5">
          <CurrencyDollarIcon className="h-6 w-6  text-white" aria-hidden="true" />
        
          </div>
          <div className="flex-grow">
            <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
              Reduce Cost
            </h2>
            <p className="leading-relaxed text-base">
            Leverage the benefits of cost-effective solutions due to our business and technology partnerships as well as years of industry experience
            </p>
        
          </div>
        </div>
        <div className="flex flex-col mb-10 lg:items-start items-center">
          <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-black text-indigo-500 mb-5">
         <TrendingUpIcon className="h-6 w-6  text-white" aria-hidden="true"/>
          </div>
          <div className="flex-grow">
            <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
             Client Profitability
            </h2>
            <p className="leading-relaxed text-base">
            Enhance their profitability, market position, and their value within their industry with technology-enabled business solutions.
            </p>
            
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="text-gray-700 body-font border-t border-gray-200">
    <div className="container px-5 py-24 mx-auto">
      <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
         Our Values
        </h1>
        <p className="lg:w-1/2 w-full leading-relaxed text-base">
        Our culture, practical disruptive innovations and our relationships with clients, employees, and communities shape our core values. With a mindset of creating a business with authentic integrity and carving a niche for ourselves, we deliver excellence with commitment and transparency. Our core values are as listed below:
        </p>
      </div>
      <div className="flex flex-wrap -m-4">
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className="border border-gray-300 p-6 rounded-lg">
            <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-black text-indigo-500 mb-4">
             <ArrowsExpandIcon className="h-6 w-6  text-white" aria-hidden="true" />
            </div>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            Agile & Flexibility
            </h2>
            <p className="leading-relaxed text-base">
            We uses Lean Design alongside Agile Development methodologies. This means we’ll be seeking user feedback early, </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className="border border-gray-300 p-6 rounded-lg">
            <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-black text-indigo-500 mb-4">
            <SparklesIcon className="h-6 w-6  text-white" aria-hidden="true"/>
            </div>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            Quality
            </h2>
            <p className="leading-relaxed text-base">
            We believe in excellence and thus, strive to achieve superior quality in all our work, solutions, and products we deliver
            </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className="border border-gray-300 p-6 rounded-lg">
            <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-black text-indigo-500 mb-4">
             <EmojiHappyIcon className="h-6 w-6  text-white" aria-hidden="true"/>
            </div>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            Customer Satisfaction
            </h2>
            <p className="leading-relaxed text-base">
            We strive to achieve customer satisfaction by delivering solutions that meet their expectations in terms of quality,
            </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className="border border-gray-300 p-6 rounded-lg">
            <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-black text-indigo-500 mb-4">
              <ChipIcon className="h-6 w-6  text-white" aria-hidden="true"/>
            </div>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            Innovation
            </h2>
            <p className="leading-relaxed text-base">
            We believe in delivering innovative solutions leveraging the best of the latest technology and tools.
            </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className="border border-gray-300 p-6 rounded-lg">
            <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-black text-indigo-500 mb-4">
              {/* <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z" />
              </svg> */}

              <ClipboardCheckIcon className="h-6 w-6  text-white" aria-hidden="true"/>
            </div>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            Transparency
            </h2>
            <p className="leading-relaxed text-base">
            We maintain a transparent communication pattern as it is the key ingredient of our relationships with clients and teams.
            </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className="border border-gray-300 p-6 rounded-lg">
            <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-black text-indigo-500 mb-4">
             <HeartIcon  className="h-6 w-6  text-white" aria-hidden="true"/>
            </div>
            <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            Passion & Creativity
            </h2>
            <p className="leading-relaxed text-base">
            We strive to achieve more and be the best we can be by working hard and we love what we do
            </p>
          </div>
        </div>
      </div>
      {/* <button className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
        Button
      </button> */}
    </div>
  </section>
  {/* <section className="text-gray-700 body-font border-t border-gray-200">
    <div className="container px-5 py-24 mx-auto">
      <div className="flex flex-col text-center w-full mb-20">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
          Our Team
        </h1>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
          Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
          gentrify, subway tile poke farm-to-table. Franzen you probably haven't
          heard of them.
        </p>
      </div>
      <div className="flex flex-wrap -m-2">
        <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img
              alt="team"
              className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
              src="https://dummyimage.com/80x80/edf2f7/a5afbd"
            />
            <div className="flex-grow">
              <h2 className="text-gray-900 title-font font-medium">
                Holden Caulfield
              </h2>
              <p className="text-gray-500">UI Designer</p>
            </div>
          </div>
        </div>
        <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img
              alt="team"
              className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
              src="https://dummyimage.com/84x84/edf2f7/a5afbd"
            />
            <div className="flex-grow">
              <h2 className="text-gray-900 title-font font-medium">
                Henry Letham
              </h2>
              <p className="text-gray-500">CTO</p>
            </div>
          </div>
        </div>
        <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img
              alt="team"
              className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
              src="https://dummyimage.com/88x88/edf2f7/a5afbd"
            />
            <div className="flex-grow">
              <h2 className="text-gray-900 title-font font-medium">
                Oskar Blinde
              </h2>
              <p className="text-gray-500">Founder</p>
            </div>
          </div>
        </div>
        <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img
              alt="team"
              className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
              src="https://dummyimage.com/90x90/edf2f7/a5afbd"
            />
            <div className="flex-grow">
              <h2 className="text-gray-900 title-font font-medium">John Doe</h2>
              <p className="text-gray-500">DevOps</p>
            </div>
          </div>
        </div>
        <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img
              alt="team"
              className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
              src="https://dummyimage.com/94x94/edf2f7/a5afbd"
            />
            <div className="flex-grow">
              <h2 className="text-gray-900 title-font font-medium">
                Martin Eden
              </h2>
              <p className="text-gray-500">Software Engineer</p>
            </div>
          </div>
        </div>
        <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img
              alt="team"
              className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
              src="https://dummyimage.com/98x98/edf2f7/a5afbd"
            />
            <div className="flex-grow">
              <h2 className="text-gray-900 title-font font-medium">
                Boris Kitua
              </h2>
              <p className="text-gray-500">UX Researcher</p>
            </div>
          </div>
        </div>
        <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img
              alt="team"
              className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
              src="https://dummyimage.com/100x90/edf2f7/a5afbd"
            />
            <div className="flex-grow">
              <h2 className="text-gray-900 title-font font-medium">
                Atticus Finch
              </h2>
              <p className="text-gray-500">QA Engineer</p>
            </div>
          </div>
        </div>
        <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img
              alt="team"
              className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
              src="https://dummyimage.com/104x94/edf2f7/a5afbd"
            />
            <div className="flex-grow">
              <h2 className="text-gray-900 title-font font-medium">
                Alper Kamu
              </h2>
              <p className="text-gray-500">System</p>
            </div>
          </div>
        </div>
        <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img
              alt="team"
              className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
              src="https://dummyimage.com/108x98/edf2f7/a5afbd"
            />
            <div className="flex-grow">
              <h2 className="text-gray-900 title-font font-medium">
                Rodrigo Monchi
              </h2>
              <p className="text-gray-500">Product Manager</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}



  
  <section className="text-gray-700 body-font border-t border-gray-200">
    <div className="container px-5 py-24 mx-auto">
      <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="black"
          className="inline-block w-8 h-8 text-gray-400 mb-8"
          viewBox="0 0 975.036 975.036"
        >
          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z" />
        </svg>
        <p className="leading-relaxed text-lg">
        Let’s go invent tomorrow rather than worrying about what happened yesterday.
        </p>
        <span className="inline-block h-1 w-10 rounded  mt-8 mb-6" />
        <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
        Steve Jobs
        </h2>
     
      </div>
    </div>
  </section>





  



  <Newsletter />
    <Footer/>
</div>





  )
}


